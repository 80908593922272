import React from "react";
import Button from "@material-ui/core/Button";
import Receipt from "@material-ui/icons/Receipt";
import Archive from "@material-ui/icons/Archive";
import Unarchive from "@material-ui/icons/Unarchive";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
//
import axios from "axios";
 
  class WithButton extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        errors: { general: "" },
        winner: {},
        archive:props.sweepstake.archive,
        sweepstake: {}
      };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.setState({
        archive: nextProps.sweepstake.archive
      });
    }

    handleLiveClick() {
      this.props.handleAnimation();
      this.setState({
        // loading: true,
        errors: { general: "" }
      });

      // this.getWinner(this.props.sweepstake.id);
      this.interval = setTimeout(
        () =>
          this.setState({
            loading: false
          }),
        8000
      );
    }
    handleArchiveClick() {
      axios
        .post("/admin_sweepstakes/archive.php", {
          id: this.props.sweepstake.id,
          archive: true
        })
        .catch(err => console.log(err));

      this.setState({ archive: "1" });
    }
    handleUnarchiveClick() {
      axios
        .post("/admin_sweepstakes/archive.php", {
          id: this.props.sweepstake.id,
          archive: false
        })
        .catch(err => console.log(err));

      this.setState({ archive: "0" });
    }

    componentWillUnmount() {
      clearInterval(this.interval);
    }

    getWinner($sweepstakeId) {
      axios
        .post("/get_winner.php", { id: $sweepstakeId })
        .then(res => {
          this.setState({ ...res.data });
        })
        .catch(err => console.log(err));
    }

    render() {
      const archiveButton =
        this.state.archive == 0 ? (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<Archive />}
            onClick={() => this.handleArchiveClick()}
          >
            Mover para Anteriores
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            startIcon={<Unarchive />}
            onClick={() => this.handleUnarchiveClick()}
          >
            Mover para Atual
          </Button>
        );
      const sweepstakeButton = (
        <>
          <Button
            onClick={() => {
              this.handleLiveClick();
            }}
            variant="contained"
            color="primary"
            size="large"
            startIcon={<Receipt />}
          >
            Sortear !!!
            {this.props.loading && (
              <CircularProgress color="secondary" size={30} />
            )}
          </Button>
          <br />
          <br />
          {this.props.errors.general && (
            <Typography
              className="animated flash infinite slow"
              variant="body2"
              style={{ color: "black", fontWeight: "bold" }}
            >
              {this.props.errors.general}
            </Typography>
          )}
        </>
      );

      return (
        <div className="">
         
          <div className="admin-content" align="center">
            {sweepstakeButton}
            <br />
            {archiveButton}
          </div>

          <br />
          <br />
          <br />
        </div>
      );
    }
  }
   
export default  WithButton
 
