import React, { Component } from "react";

import axios from "axios";

// axios.defaults.headers.common["Authorization"] = 123;

class test extends Component {
  componentDidMount() {
    axios.post("/getuserid.php").then(response => {
      console.log("response:::", response.data);
    });

    // axios({
    //   method: "post",
    //   url: "/getuserid.php",
    //   data: {
    //     firstName: "Fred",
    //     lastName: "Flintstone"
    //   }
    //   //   headers: { Authorization: 123, "Access-Control-Allow-Origin": "*" }
    //   //   withCredentials: true
    // });
  }

  state = { value: null };
  render() {
    return <p>""</p>;
  }
}

export default test;
