import React, { Component } from "react";

// Components
import DashboardView from "./dashboard-view";

class DashboardContainer extends Component {
  render() {
    const text = "Painel Administrativo, selecione uma configuração no menu.";
    return <DashboardView text={text} />;
  }
}

export default DashboardContainer;
