import React, { useState } from "react";
// MUI
import { fade, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Tooltip from "@material-ui/core/Tooltip";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Edit from "@material-ui/icons/edit";
import InputBase from "@material-ui/core/InputBase";
import Box from "@material-ui/core/Box";
import DefaultButton from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
//
import Button from "./create";
import ButtonUserInfo from "./create/userinfo-view";
//
import axios from "axios";
// Redux
import { getCustomers } from "../../redux/actions/dataActions";
import { connect } from "react-redux";

// MUI
import withStyles from "@material-ui/core/styles/withStyles";

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto"
    }
  },
  searchIcon: {
    width: theme.spacing(7),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: 200
    }
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex"
    }
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none"
    }
  }
}));
const styles = theme => ({});

function SimpleTable({
  users,
  handleSubmit,
  getCustomers,
  handleSearch,
  term
}) {
  const classes = useStyles();

  const headers = [
    // { text: "Pedido" },
    { text: "Créditos" },
    { text: "Perfil" },
    { text: "Nome completo" },
    { text: "E-mail" },
    { text: "CPF" },
    { text: "Telefone" },
    { text: "CEP" },
    { text: "Logradouro" },
    { text: "Numero" },
    { text: "Complemento" },
    { text: "Bairro" },
    { text: "Cidade" },
    { text: "Estado" }
  ];

  const deleteUser = id => {
    let userData = { id: id };

    axios
      .post("/admin_customers/delete.php", userData)
      .then(res => {
        getCustomers();
      })
      .catch(err => {
        console.log("credit catch: ", err);
      });
  };

  const handleDelete = $id => {
    if (confirm("Excluir usuário!! Prosseguir?")) {
      deleteUser($id);
      alert("Usuário removido com sucesso.");
    } else {
      alert("Operação cancelada.");
    }
  };
  return (
    <>
      <br />
      <div align="center">
        <Typography
          style={{ display: "inline-block", marginTop: 20 }}
          align="center"
          gutterBottom
          variant="h5"
          component="h2"
        >
          Clientes
        </Typography>

        <div style={{ display: "inline-block" }} className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Busca..."
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput
            }}
            inputProps={{ "aria-label": "search" }}
            onChange={e => handleSearch(e)}
            value={term}
          />
        </div>
        <Box style={{ margin: 20, float: "left" }}>
          <ButtonUserInfo
            handleSubmit={handleSubmit}
            getCustomers={getCustomers}
          >
            <DefaultButton variant="contained" color="primary">
              Criar
            </DefaultButton>
          </ButtonUserInfo>
        </Box>
      </div>
      <TableContainer
        // className="admin-customer"
        component={Paper}
        style={{
          marginTop: 20,
          fontSize: 8,
          maxWidth: "100vw",
          maxHeight: "calc(100vh - 183px)"
        }}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Ação</TableCell>
              {headers.map(item => (
                <TableCell key={item.text}>{item.text}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(row => {
              if (row.username != "Administrador") {
                return (
                  <TableRow key={row.email}>
                    <TableCell>
                      <IconButton
                        style={{ marginTop: 8 }}
                        edge="end"
                        aria-label="delete"
                      >
                        <Button handleSubmit={handleSubmit} user={row}>
                          <MonetizationOnIcon />
                        </Button>
                      </IconButton>
                      <IconButton
                        style={{ marginTop: 8 }}
                        edge="end"
                        aria-label="Editar"
                      >
                        <ButtonUserInfo
                          handleSubmit={handleSubmit}
                          user={row}
                          getCustomers={getCustomers}
                        >
                          <Edit />
                        </ButtonUserInfo>
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="Excluir"
                        onClick={() => handleDelete(row.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                    {Object.keys(row).map(function(key, index) {
                      if (key != "id" && key != "pedido") {
                        if (key == "roles_mask" && row[key] == 1) {
                          return <TableCell>Administrador</TableCell>;
                        } else if (key == "roles_mask") {
                          return <TableCell>Usuário</TableCell>;
                        }
                        return <TableCell>{row[key]}</TableCell>;
                      }
                    })}
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const mapStateToProps = state => ({
  customers: state.data.customers,
  UI: state.UI
});

const mapActionsToProps = {
  getCustomers
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(SimpleTable));
