// React imports
import React, { Component, useState } from "react";
import ReactDOM from "react-dom";
// import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { HashRouter as Router, Switch, Route, Link } from "react-router-dom";
// Material UI imports
import Container from "@material-ui/core/Container";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
// Redux imports
import { connect } from "react-redux";
// Other imports
import axios from "axios";
// Style
import "./app.scss";
// Components
import themeObject from "./util/theme";
import Sidebar from "./components/sidebar";
import Tickets from "./components/tickets";
import SweepStakes from "./pages/sweepstakes";
import Navbar from "./components/navbar";
import NavbarAdmin from "./components/admin-navbar";
import AdminSweepstake from "./components/admin-sweepstake";
import AdminLiveSweepstakes from "./components/admin-live-sweepstakes";
import AdminLiveTickets from "./components/admin-live-tickets";
import AdminCustomer from "./components/admin-customer";
import Dashboard from "./components/dashboard";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import EditUser from "./pages/EditUser";
import AuthRoute from "./util/AuthRoute";
import AuthRouteAdmin from "./util/AuthRouteAdmin";
import SignInComplete from "./pages/signInComplete";
import WithButton from "./components/admin-live-tickets/admin-live-tickets-withButton";
//
import dev from "./dev";
// API
import { api } from "./services/api";

axios.defaults.baseURL = api;

const theme = createMuiTheme(themeObject);

let token = localStorage.userId;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authenticated: true,
      isAdmin: true,
      user: {},
      width: window.innerWidth,
    };
  }

  verifyLogin = () => {
    axios
      .post("verifylogin.php")
      .then((res) => {
        this.setState({ authenticated: res.data.isLogged });
        this.setState({ isAdmin: res.data.isAdmin });

        localStorage.setItem("isLogged", res.data.isLogged);
        localStorage.setItem("isAdmin", res.data.isAdmin);

        // if (res.data == true) {
        //   this.setState({ authenticated: res.data.isLogged });
        //   this.setState({ authenticated: true });
        //   console.log("authenticated:::: true");
        // } else {
        //   this.setState({ authenticated: true });
        //   console.log("authenticated:::: false");
        // }
      })
      .catch((err) => console.log(err));
  };

  componentDidMount() {
    // this.verifyLogin();
    // if (token != undefined) {
    //   this.setState({ authenticated: true });
    //   console.log("authenticated: true");
    // } else if (token == undefined) {
    //   console.log("authenticated: false");
    //   this.setState({ authenticated: false });
    // }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.authenticated != nextProps.authenticated) {
      if (nextProps.authenticated != undefined) {
        this.setState({ authenticated: nextProps.authenticated });
      }
    }
  }

  // Handle window size

  componentWillMount() {
    this.verifyLogin();
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };
  //

  render() {
    const { user } = this.state;
    const { width } = this.state;
    const isMobile = width <= 500;

    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <div className="app">
            <AuthRouteAdmin
              isAdmin={localStorage.getItem("isAdmin")}
              authenticated={this.state.authenticated}
              path="/admin"
              component={NavbarAdmin}
            />
            <Switch>
              {isMobile ? (
                "hello"
              ) : (
                <AuthRouteAdmin
                  isAdmin={localStorage.getItem("isAdmin")}
                  authenticated={this.state.authenticated}
                  path="/admin"
                  component={Sidebar}
                />
              )}
              {/* <Route path="/" component={Navbar} /> */}
            </Switch>
            {/* <Container> */}
            <Switch>
              {/* dev */}
              <Route path="/dev" component={dev} />
              {/* dev */}
              {/* CUSTOMER PAGES */}
              <Route path="/entrar" component={SignIn} />

              {/* <Route path="/cadastrar" component={SignUp} /> */}
              <Route path="/cadastrar" component={SignInComplete} />
              <AuthRoute
                authenticated={this.state.authenticated}
                path="/"
                exact
                component={() => <SweepStakes />}
              />
              <AuthRoute
                authenticated={this.state.authenticated}
                path="/editar"
                component={EditUser}
              />
              <AuthRoute
                authenticated={this.state.authenticated}
                exact
                path="/cartela/:id"
                component={Tickets}
              />
              {/* ADMIN  PAGES */}
              <Route path="/admin/entrar" component={SignIn} />
              <AuthRouteAdmin
                isAdmin={localStorage.getItem("isAdmin")}
                authenticated={this.state.authenticated}
                exact
                path="/admin/sorteios"
                component={AdminSweepstake}
              />
              <AuthRouteAdmin
                isAdmin={localStorage.getItem("isAdmin")}
                authenticated={this.state.authenticated}
                exact
                path="/admin/live_sorteios"
                component={AdminLiveSweepstakes}
              />
              <AuthRouteAdmin
                isAdmin={localStorage.getItem("isAdmin")}
                authenticated={this.state.authenticated}
                exact
                path="/admin/live_cartela/:id"
                component={AdminLiveTickets}
              />
              <AuthRouteAdmin
                isAdmin={localStorage.getItem("isAdmin")}
                authenticated={this.state.authenticated}
                exact
                path="/admin/clientes"
                component={AdminCustomer}
              />
              <AuthRouteAdmin
                isAdmin={localStorage.getItem("isAdmin")}
                authenticated={this.state.authenticated}
                exact
                path="/admin/"
                exact
                component={Dashboard}
              />
              <Route path="/" render={() => <div>Não Encontrado</div>} />
            </Switch>
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // user: state.user
    authenticated: state.user.authenticated,
    // auth: state.authentication.auth,
    // profile: state.authentication.profile
  };
};

export default connect(mapStateToProps)(App);
