import React, { Component } from "react";
import Ticket from "../Ticket";
import Typography from "@material-ui/core/Typography";

import axios from "axios";

// Components
import TicketsView from "./admin-live-tickets-view";
// Redux
import { connect } from "react-redux";
import { removeCredit } from "../../redux/actions/userActions";
import { getSweepstakes } from "../../redux/actions/dataActions";
import { getCustomer } from "../../redux/actions/userActions";

// Style
import "./tickets.css";

//
import AdminButtons from "./admin-live-tickets-withButton";
import Winner from "./admin-sweepstake-popup";

const styles = theme => ({});

class AdminLiveTicketsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // sweepstake: this.props.location.state.sweepstake,
      sweepstake: {},
      clients: [],
      loading: false,
      winner: {},
      errors: {},
      openModal: false
    };
  }

  componentDidMount() {
    if (localStorage.userId == undefined) this.props.history.push("/entrar");
    this.props.getCustomer({ id: localStorage.userId });

    this.props.getSweepstakes();
    this.getSavedNumbers();

    this.interval = setInterval(() => this.handleUpdate(), 5000);

    let id = this.props.match.params.id;
    this.setState({
      id: id
    });
  }

  handleUpdate() {
    // only getSavedNumbers when not animating screen so it wont interrupt the animation.
    if (!this.props.loading) {
      // get updated info
      this.getSavedNumbers();
      this.props.getSweepstakes();
    }
  }

  handleAnimation = () => {
    this.setState({
      loading: true,
      errors: { general: "" }
    });
    this.interval = setTimeout(
      () =>
        this.setState({
          loading: false
        }),
      6000
    );
    this.getWinner();

    console.log("handleanimation");
  };
  getWinner() {
    axios
      .post("/get_winner.php", { id: this.state.sweepstake.id })
      .then(res => {
        this.setState({ ...res.data });
      })
      .catch(err => console.log(err));
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let ss = nextProps.sweepstakes.filter(ss => ss.id == this.state.id);
    this.setState({
      sweepstake: { ...ss[0], loading: this.props.loading }
    });

    if (ss[0] != undefined) {
      if (ss[0].winner > 0) {
        this.setState({ openModal: true });
      }
    }
  }

  getSavedNumbers = () => {
    axios
      .post("/getSavedNumbers.php", {
        sweepstakeId: this.props.match.params.id
      })
      .then(res => {
        this.setState({ clients: res.data });
      });
  };

  handleSaveNumber = itemData => {
    axios
      .post("/user_ticket.php", itemData)
      .then(res => {
        alert("Número salvo com sucesso");
        this.getSavedNumbers();
        this.props.getCustomer({ id: this.props.user.id });
      })
      .catch(err => console.log(err));
  };

  render() {
    let tickets = [];
    let rows = [];
    let sweepstakeId = this.props.match.params.id;

    // Push tickets to array

    for (var i = 1; i <= this.state.sweepstake.numberOfTickets; i++) {
      var result = Array.isArray(this.state.clients)
        ? this.state.clients.find(obj => {
            return obj.ticket_number == i;
          })
        : undefined;

      tickets.push({
        number: i,
        userName: result != undefined ? result.username : "",
        sweepstakeId
      });

      // Group tickets by groups of ten

      if (i % 10 == 0) {
        rows.push({ rowNumber: i, tickets });
        tickets = [];
      }

      if (this.state.sweepstake.numberOfTickets == i) {
        rows.push({ rowNumber: i, tickets });
        break;
      }
    }

    return (
      <>
        <TicketsView
          sweepstake={this.state.sweepstake}
          ticketsRows={rows}
          clients={this.state.clients}
          handleTicketSave={this.handleSaveNumber}
          loading={this.state.loading}
          winner={this.state.winner}
        />
        <AdminButtons
          sweepstake={this.state.sweepstake}
          handleAnimation={this.handleAnimation}
          loading={this.state.loading}
          errors={this.state.errors}
        />
        <Winner openModal={this.state.openModal} ss={this.state.sweepstake} />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    sweepstakes: state.data.sweepstakes
  };
};

const mapActionsToProps = {
  getCustomer,
  getSweepstakes
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(AdminLiveTicketsContainer);
