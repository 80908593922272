import React from "react";
//
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
////
import { maskInput } from "../../../util/maskedInput";
//
import axios from "axios";

const styles = theme => ({
  ...theme.spreadIt
});

class EditUser extends React.Component {
  constructor(props) {
    super(props);
    if (props.user == undefined) {
      this.state = { user: {}, errors: {} };
      this.api = "/userCreate.php";
    } else {
      this.state = { user: props.user, errors: {} };
      this.api = "/userUpdate.php";
    }
  }

  handleChange = (event, mask) => {
    let masked = maskInput(event.target.value, mask);

    this.setState({
      user: {
        ...this.state.user,
        [event.target.id]: masked
      }
    });

    if (mask == "cep") {
      axios.get("/cep_api.php?cep=" + event.target.value).then(res => {
        this.setState({
          user: {
            ...this.state.user,
            logradouro: res.data["logradouro"],
            bairro: res.data["bairro"],
            cidade: res.data["municipio"],
            estado: res.data["uf"]
          },
          errors: {
            general: res.data["input-codigo-error"]
          }
        });
      });
    }
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      loading: true
    });
    axios
      .post(this.api, this.state.user)
      .then(res => {
        if (res.data.success != undefined) {
          this.props.getCustomers();
          alert("Usuário cadastrado!");
          this.props.handleClose();
        } else if (res.data && res.data.error == undefined) {
          this.props.getCustomers();
          this.props.handleClose();
          return true;
        } else {
          if (res.data.error != undefined) {
            this.setState({
              errors: res.data.error
            });
          }
        }
      })
      .then(result => {
        result ? this.setState({ success: true }) : "";
      })

      .catch(err => console.log(err));
  };

  render() {
    const { classes } = this.props;

    const formFields1 = [
      {
        required: true,
        id: "username",
        label: "Nome Completo",
        type: "text"
      },
      {
        required: true,
        id: "email",
        label: "E-Mail",
        type: "text"
      },
      {
        required: true,
        id: "password",
        label: "Senha",
        type: "new-password"
      },
      {
        required: true,
        id: "cpf",
        label: "CPF",
        type: "text"
      }
    ];

    const formFields2 = [
      {
        required: true,
        id: "telefone",
        label: "Telefone",
        type: "text"
      },
      {
        required: true,
        id: "cep",
        label: "CEP",
        type: "text"
      },
      {
        required: true,
        id: "logradouro",
        label: "Rua",
        type: "text"
      },
      {
        required: true,
        id: "numero",
        label: "Número",
        type: "text"
      }
    ];

    const formFields3 = [
      {
        required: false,
        id: "complemento",
        label: "Complemento",
        type: "text"
      },
      {
        required: true,
        id: "bairro",
        label: "Bairro",
        type: "text"
      },
      {
        required: true,
        id: "cidade",
        label: "Cidade",
        type: "text"
      },
      {
        required: true,
        id: "estado",
        label: "Estado",
        type: "text"
      }
    ];

    return (
      <div>
        <form
          style={{ display: "flex", padding: "0px 20px" }}
          onSubmit={this.handleSubmit}
        >
          <Grid container justify="center" spacing={2}>
            <Grid item sm={12} md={12} className="" align="center">
              {formFields1.map(field => (
                <>
                  <TextField
                    inputProps={{
                      autoComplete: "new-password",
                      form: {
                        autoComplete: "off"
                      }
                    }}
                    key={field.id}
                    fullWidth
                    style={{ margin: "10px 0px" }}
                    className={classes.textField}
                    required={field.required}
                    id={field.id}
                    label={field.label}
                    type={field.type}
                    InputLabelProps={{
                      shrink: true
                    }}
                    variant="standard"
                    value={this.state.user[field.id]}
                    onChange={e => this.handleChange(e, field.id)}
                  />

                  {this.state.errors[field.id] && (
                    <Typography
                      variant="body2"
                      className={classes.customError}
                      style={{ color: "red" }}
                    >
                      {this.state.errors[field.id]}
                    </Typography>
                  )}
                </>
              ))}
            </Grid>
            <Grid item sm={12} md={12} className="" align="center">
              {formFields2.map(field => (
                <TextField
                  key={field.id}
                  fullWidth
                  style={{ margin: "10px 0px" }}
                  className={classes.textField}
                  required={field.required}
                  id={field.id}
                  label={field.label}
                  type={field.type}
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="standard"
                  value={this.state.user[field.id]}
                  onChange={e => this.handleChange(e, field.id)}
                />
              ))}
            </Grid>
            <Grid item sm={12} md={12} className="" align="center">
              {formFields3.map(field => (
                <TextField
                  key={field.id}
                  fullWidth
                  style={{ margin: "10px 0px", textAlign: "left" }}
                  className={classes.textField}
                  required={field.required}
                  id={field.id}
                  label={field.label}
                  type={field.type}
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="standard"
                  value={this.state.user[field.id]}
                  onChange={e => this.handleChange(e, field.id)}
                />
              ))}
            </Grid>
            <Grid item sm={6} align="center" className="form">
              <FormControl
                className={classes.formControl}
                style={{
                  width: "200px",
                  display: "block"
                }}
              >
                <InputLabel id="roles_mask">Perfil</InputLabel>
                <Select
                  style={{ width: 200 }}
                  labelId="roles_mask"
                  id="roles_mask"
                  value={this.state.user.roles_mask}
                  onChange={e =>
                    this.handleChange({
                      target: { id: "roles_mask", value: e.target.value }
                    })
                  }
                >
                  <MenuItem value={"0"}>Usuário</MenuItem>
                  <MenuItem value={"1"}>Administrador</MenuItem>
                </Select>
              </FormControl>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                // disabled={loading}
                style={{ marginTop: 20 }}
              >
                Salvar
              </Button>
              <br />
              <br />
              {this.state.errors.general && (
                <Typography
                  variant="body2"
                  className={classes.customError}
                  style={{ color: "red" }}
                >
                  {this.state.errors.general}
                </Typography>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(EditUser);
