import React, { useState } from "react";
// MUI
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Box from "@material-ui/core/Box";

//
import axios from "axios";
// Components
import Button from "./create";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  button: {
    margin: 20,
  },
});

export default function SimpleTable({
  sweepstakes,
  ticketArray,
  getSweepstakes,
}) {
  const classes = useStyles();

  const headers = [
    { text: "Titulo" },
    { text: "Data Final" },
    { text: "Quantidade Total" },
    { text: "Quantidade Selecionado" },
    { text: "Quantidade Disponível" },
    { text: "Ganhador" },
    { text: "Email" },
    { text: "Telefone" },
  ];

  const handleDelete = ($id) => {
    if (confirm("Excluir sorteio!! Prosseguir?")) {
      deleteItem($id);
      alert("Sorteio removido com sucesso.");
    } else {
      alert("Operação cancelada.");
    }
  };

  const handleEdit = ($id) => {};

  const deleteItem = (id) => {
    let itemData = { id: id };

    axios
      .post("/admin_sweepstakes/delete.php", itemData)
      .then((res) => {
        getSweepstakes();
      })
      .catch((err) => {
        console.log("delete sweepstake catch: ", err);
      });
  };
  const handleImageChange = (event, id) => {
    const fd = new FormData();
    fd.append("image", event.target.files[0], event.target.files[0].name);
    fd.append("id", id);
    axios.post("/image.php", fd).then((res) => {});
  };

  return (
    <Grid container className={classes.form}>
      <Grid item sm />
      <Grid item sm>
        <br />
        <Typography
          style={{ marginTop: 20 }}
          align="center"
          gutterBottom
          variant="h5"
          component="h2"
        >
          Sorteios
        </Typography>
        <Box style={{ margin: 20 }}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            getSweepstakes={getSweepstakes}
          >
            Criar
          </Button>
        </Box>

        <TableContainer
          component={Paper}
          style={{ marginTop: 20, maxWidth: "100vw" }}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {headers.map((item) => (
                  <TableCell key={item.text} align="center">
                    {item.text}
                  </TableCell>
                ))}
                <TableCell>Ação</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sweepstakes.lenght < 1
                ? ""
                : sweepstakes.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {row.title != undefined
                          ? row.title.replace(/&quot;/g, '"')
                          : null}
                      </TableCell>
                      <TableCell align="center">{row.endDate}</TableCell>
                      <TableCell align="center">
                        {row.numberOfTickets}
                      </TableCell>
                      <TableCell align="center">{row.selected}</TableCell>
                      <TableCell align="center">{row.available}</TableCell>

                      {/*  */}
                      <TableCell align="center">
                        <div style={{ width: "120px" }}>{row.username}</div>
                      </TableCell>
                      <TableCell align="center" style={{ width: 300 }}>
                        {row.email}
                      </TableCell>
                      <TableCell align="center">
                        <div style={{ width: "120px" }}>{row.telefone}</div>
                      </TableCell>

                      {/*  */}
                      <TableCell>
                        <div style={{ width: "90px" }}>
                          <div className="inputWrapper">
                            <input
                              className="fileInput"
                              type="file"
                              name="image"
                              onChange={(e) => handleImageChange(e, row.id)}
                            />
                          </div>

                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleDelete(row.id)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item sm />
    </Grid>
  );
}
