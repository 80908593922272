import React, { Component } from "react";
import CustomerView from "./admin-customer-view";
import axios from "axios";

// MUI
import withStyles from "@material-ui/core/styles/withStyles";

// Redux
import { getCustomers } from "../../redux/actions/dataActions";
import { connect } from "react-redux";

import "./admin-customer.scss";

const styles = theme => ({});

function searchingFor(term) {
  return function(user) {
    return user.username.toLowerCase().includes(term.toLowerCase()) || !term;
  };
}

class AdminCustomerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      term: ""
    };
  }

  componentDidMount() {
    if (localStorage.userId == undefined) this.props.history.push("/entrar");
    this.props.getCustomers();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.customers) {
      this.setState({ users: this.props.customers });
    }
  }

  handleSearch = event => {
    this.setState({ term: event.target.value });
  };

  render() {
    const { customers: users } = this.props;
    const { term } = this.state;
    return (
      <div className="admin-customer admin-content">
        <CustomerView
          fields={this.fields}
          users={users.filter(searchingFor(term))}
          handleSubmit={this.handleSubmit}
          handleSearch={this.handleSearch}
          term={term}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  customers: state.data.customers,
  UI: state.UI
});

const mapActionsToProps = {
  getCustomers
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(AdminCustomerContainer));
