import React, { Component } from "react";
import { Route, Redirect, withRouter } from "react-router-dom";
// MUI
import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";
import Divider from "@material-ui/core/Divider";
// Components
import Card from "./sweepstakes-view.js";
import "./sweepstakes";
//
import axios from "axios";
// Redux
import { getSweepstakes } from "../../redux/actions/dataActions";
import { getCustomer } from "../../redux/actions/userActions";
import { connect } from "react-redux";
import Navbar from "../../components/navbar";

const styles = theme => ({});

class SweepstakesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      sweepstakes: [],
      posts: []
    };
  }

  componentDidMount() {
    if (localStorage.userId == undefined) this.props.history.push("/entrar");
    this.interval = setInterval(() => this.recurrentFunctions(), 10000);
    this.props.getSweepstakes();
    // axios
    //   .post("/admin_customers/read_customer.php", { id: this.state.user.id })
    //   .then(res => {
    //     this.setState({ user: res.data });
    //   });
    this.props.getCustomer({ id: this.props.user.id });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  recurrentFunctions() {
    () => this.props.getSweepstakes();
  }

  verifyEmptyUserFields = $userData => {
    let user = this.props.user;
    let result = false;
    let debug = Object.keys(user).map(function(key, index) {
      if (user[key] == "") result = true;
    });

    if (result) return true;
  };

  render() {
    const { sweepstakes, user, getSweepstakes } = this.props;
    const { posts } = this.state;

    let activeSweepstakes = this.props.sweepstakes
      .filter(ss => ss.archive == 0)
      .map(ss => {
        return <Card key={ss.id} sweepstake={ss} user={this.props.user} />;
      });

    let dueSweepstakes = this.props.sweepstakes
      .filter(ss => ss.archive == 1)
      .map(ss => (
        <Card key={ss.id} sweepstake={ss} user={this.props.user} fade={true} />
      ));

    // if (this.verifyEmptyUserFields()) return <Redirect to="/editar" />;

    return (
      <div>
        <Navbar />
        <br />
        <br />
        <Typography align="center" variant="h3" gutterBottom>
          Campanha Atual
        </Typography>
        <div className="sweepstake-list">
          {sweepstakes.length < 1 ? "" : [activeSweepstakes]}
        </div>
        <br />
        <br />
        <Divider variant="middle" />
        <br />
        <br />
        <Typography align="center" variant="h3" gutterBottom>
          Sorteios Passados
        </Typography>
        <div className="sweepstake-list">{dueSweepstakes}</div>
      </div>
    );
  }
}

// REDUX
const mapStateToProps = state => ({
  sweepstakes: state.data.sweepstakes,
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  getSweepstakes,
  getCustomer
};

// export default connect(
//   mapStateToProps,
//   mapActionsToProps
// )(withStyles(styles)(SweepstakesContainer));

export default withRouter(
  connect(
    mapStateToProps,
    mapActionsToProps
  )(withStyles(styles)(SweepstakesContainer))
);
