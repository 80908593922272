import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// Icons
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Menu from "@material-ui/icons/Menu";
import MailIcon from "@material-ui/icons/Mail";
import Home from "@material-ui/icons/Home";
import PeopleAlt from "@material-ui/icons/PeopleAlt";
import Receipt from "@material-ui/icons/Receipt";
import LiveTv from "@material-ui/icons/LiveTv";

// Style
import "./sidebar.scss";

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
});

export default function Sidebar() {
  const classes = useStyles();

  const menuItens = [
    { text: "Home", icon: <Home />, link: "/admin" },
    { text: "Sorteios", icon: <Receipt />, link: "/admin/sorteios" },
    { text: "Clientes", icon: <PeopleAlt />, link: "/admin/clientes" },
    { text: "Live", icon: <LiveTv />, link: "/admin/live_sorteios" }
  ];

  return (
    <List className="sidebar">
      {menuItens.map((item, index) => (
        <Link className="link" key={item.text} to={item.link}>
          <ListItem button>
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        </Link>
      ))}
    </List>
  );
}
