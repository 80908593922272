import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const AuthRoute = ({
  component: Component,
  authenticated,
  isAdmin,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (localStorage.isAdmin == "true") {
          return <Component {...props} />;
        } else {
          return <Redirect to="/entrar" />;
        }
      }}
    />
  );
};
const mapStateToProps = state => ({
  // authenticated: state.user.authenticated
});

AuthRoute.propTypes = {
  user: PropTypes.object
};

export default connect(mapStateToProps)(AuthRoute);
