export const maskInput = (value, type) => {
  return handleChange(value, type);
};

function handleChange(value, type) {
  let mask = getMask(value, type);

  if (mask === "#") {
    return format(value, type);
  }
  const cleanValue = value.replace(/[^\d]/g, "");

  return format(cleanValue, type);
}

function format(value, type) {
  let mask = getMask(value, type);

  if (mask === "#") {
    return value;
  }

  let i = 0;
  let lastReplacedIndex = -1;
  const filledMask = mask.replace(/#/g, (_, j) => {
    if (value) {
      if (i >= value.length) {
        return "#";
      }

      lastReplacedIndex = j;
      return value[i++];
    }
  });
  return filledMask.substring(0, lastReplacedIndex + 1);
}

function getMask(value, type) {
  let mask;
  switch (type) {
    case "telefone":
      if (value.length < 11) {
        mask = "(##) ####-####";
      } else {
        mask = "(##) #-####-####";
      }
      break;
    case "cpf":
      mask = "###.###.###-##";
      break;
    case "RG":
      mask = "##.###.###-#";
      break;
    case "cep":
      mask = "#####-###";
      break;
    default:
      mask = "#";
  }

  return mask;
}
