import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3, 2)
  }
}));

export default function PaperSheet(props) {
  const classes = useStyles();

  return (
    <Paper
      style={{ display: "inline-block", margin: "12px 12px" }}
      className={classes.root}
    >
      <Typography variant="h5" component="h3">
        {props.children}
      </Typography>
    </Paper>
  );
}
