import React, { Component } from "react";
import Ticket from "../Ticket";
// MUI
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

import axios from "axios";

// Components
import TicketsView from "./tickets-view";
// Redux
import { connect } from "react-redux";
import { removeCredit } from "../../redux/actions/userActions";
import { getSweepstakes } from "../../redux/actions/dataActions";
import { getCustomer } from "../../redux/actions/userActions";
import Navbar from "../../components/navbar";

// Style
import "./tickets.css";

class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sweepstake: {},
      clients: []
    };
  }

  componentDidMount() {
    if (localStorage.userId == undefined) this.props.history.push("/entrar");
    this.props.getCustomer({ id: localStorage.userId });
    this.props.getSweepstakes();

    this.interval = setInterval(() => this.getSavedNumbers(), 10000);
    this.interval2 = setInterval(() => this.props.getSweepstakes(), 5000);
    this.interval3 = setInterval(
      () => this.props.getCustomer({ id: localStorage.userId }),
      5000
    );

    let id = this.props.match.params.id;
    this.setState({
      id: id
    });

    this.getSavedNumbers();
  }
  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.interval2);
    clearInterval(this.interval3);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    let ss = nextProps.sweepstakes.filter(ss => ss.id == this.state.id);
    if (ss != undefined) {
      this.setState({
        sweepstake: ss[0]
      });
    }
  }

  getSavedNumbers = () => {
    axios
      .post("/getSavedNumbers.php", {
        sweepstakeId: this.props.match.params.id
      })
      .then(res => {
        this.setState({ clients: res.data });
      });
  };

  handleSaveNumber = itemData => {
    axios
      .post("/user_ticket.php", itemData)
      .then(res => {
        this.getSavedNumbers();
        this.props.getCustomer({ id: this.props.user.id });
      })
      .catch(err => console.log(err));
  };

  render() {
    let tickets = [];
    let rows = [];
    let sweepstakeId = this.props.match.params.id;

    // Push tickets to array

    if (this.state.sweepstake != undefined) {
      for (var i = 1; i <= this.state.sweepstake.numberOfTickets; i++) {
        var result = Array.isArray(this.state.clients)
          ? this.state.clients.find(obj => {
              return obj.ticket_number == i;
            })
          : undefined;

        tickets.push({
          number: i,
          userName: result != undefined ? result.username : "",
          sweepstakeId
        });

        // Group tickets by groups of ten (changed to five so we can make it responsive)

        if (i % 5 == 0) {
          rows.push({ rowNumber: i, tickets });
          tickets = [];
        }

        if (this.state.sweepstake.numberOfTickets == i) {
          rows.push({ rowNumber: i, tickets });
          break;
        }
      }
    }

    return (
      <div style={{ width: "100%" }}>
        <Navbar />
        {this.state.sweepstake != undefined ? (
          <TicketsView
            sweepstake={this.state.sweepstake}
            ticketsRows={rows}
            clients={this.state.clients}
            handleTicketSave={this.handleSaveNumber}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    sweepstakes: state.data.sweepstakes
  };
};

const mapActionsToProps = {
  getCustomer,
  getSweepstakes
};
export default connect(mapStateToProps, mapActionsToProps)(index);
