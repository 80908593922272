import React, { Component } from "react";
import Ticket from "../Ticket";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

// Components
import Title from "../text-title";

// Style
import "./tickets.css";

const TicketsContainer = props => {
  const { sweepstake, ticketsRows, clients, handleTicketSave } = props;

  const rows = ticketsRows.map((rows, index) => {
    return (
      <Grid
        xs={12}
        md={12}
        lg={6}
        item
        key={rows.rowNumber}
        justify={index % 2 == 0 ? "flex-end" : "flex-start"}
        direction="row"
        container
      >
        {rows.tickets.map(ticket => {
          return (
            <Ticket
              key={ticket.number}
              number={ticket.number}
              userName={ticket.userName != undefined ? ticket.userName : ""}
              handleTicketSave={handleTicketSave}
              sweepstakeId={ticket.sweepstakeId}
              sweepstake={props.sweepstake}
            />
          );
        })}
      </Grid>
    );
  });

  return (
    <div className="tickets-view" style={{ textAlign: "center" }}>
      <br />
      {sweepstake.title != null ? <Title>{sweepstake.title}</Title> : null}
      <Title>Data: {sweepstake.endDate}</Title>
      <Grid container justify="center">
        {rows}
      </Grid>
    </div>
  );
};

export default TicketsContainer;
