import {
  SET_SCREAMS,
  SET_CUSTOMERS,
  SET_CUSTOMER,
  SET_SWEEPSTAKES,
  LOADING_DATA,
  LIKE_SCREAM,
  UNLIKE_SCREAM,
  DELETE_SCREAM,
  SET_ERRORS,
  POST_SCREAM,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_SCREAM,
  STOP_LOADING_UI,
  SUBMIT_COMMENT
} from "../types";
import axios from "axios";

// Get all screams
export const getScreams = () => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios
    .get("/screams")
    .then(res => {
      dispatch({
        type: SET_SCREAMS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: SET_SCREAMS,
        payload: []
      });
    });
};
// Get all customers
export const getCustomers = () => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios
    .get("/admin_customers/read.php")
    .then(res => {
      dispatch({
        type: SET_CUSTOMERS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: SET_CUSTOMERS,
        payload: []
      });
    });
};

// // Get one customers
// export const getCustomer = itemData => dispatch => {
//   console.log("getCustomer", itemData);
//   dispatch({ type: LOADING_DATA });
//   axios
//     .post("/admin_customers/read_customer.php", itemData)
//     .then(res => {
//       console.log("getCustomer res:", res.data);
//       dispatch({
//         type: SET_USER,
//         payload: res.data
//       });
//     })
//     .catch(err => {
//       dispatch({
//         type: SET_CUSTOMER,
//         payload: []
//       });
//     });
// };
export const getSweepstakes = () => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios
    .get("/admin_sweepstakes/read.php")
    .then(res => {
      dispatch({
        type: SET_SWEEPSTAKES,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: SET_SWEEPSTAKES,
        payload: []
      });
    });
};
export const getScream = screamId => dispatch => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/scream/${screamId}`)
    .then(res => {
      dispatch({
        type: SET_SCREAM,
        payload: res.data
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch(err => console.log(err));
};
// Post a scream
export const postScream = newScream => dispatch => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/scream", newScream)
    .then(res => {
      dispatch({
        type: POST_SCREAM,
        payload: res.data
      });
      dispatch(clearErrors());
    })
    .catch(err => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};
// Like a scream
export const likeScream = screamId => dispatch => {
  axios
    .get(`/scream/${screamId}/like`)
    .then(res => {
      dispatch({
        type: LIKE_SCREAM,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};
// Unlike a scream
export const unlikeScream = screamId => dispatch => {
  axios
    .get(`/scream/${screamId}/unlike`)
    .then(res => {
      dispatch({
        type: UNLIKE_SCREAM,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};
// Submit a comment
export const submitComment = (screamId, commentData) => dispatch => {
  axios
    .post(`/scream/${screamId}/comment`, commentData)
    .then(res => {
      dispatch({
        type: SUBMIT_COMMENT,
        payload: res.data
      });
      dispatch(clearErrors());
    })
    .catch(err => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};
export const deleteScream = screamId => dispatch => {
  axios
    .delete(`/scream/${screamId}`)
    .then(() => {
      dispatch({ type: DELETE_SCREAM, payload: screamId });
    })
    .catch(err => console.log(err));
};

export const getUserData = userHandle => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios
    .get(`/user/${userHandle}`)
    .then(res => {
      dispatch({
        type: SET_SCREAMS,
        payload: res.data.screams
      });
    })
    .catch(() => {
      dispatch({
        type: SET_SCREAMS,
        payload: null
      });
    });
};

export const clearErrors = () => dispatch => {
  dispatch({ type: CLEAR_ERRORS });
};
