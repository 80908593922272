import {
  SET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  MARK_NOTIFICATIONS_READ
} from "../types";
import axios from "axios";

import store from "../store";
import { SET_AUTHENTICATED } from "../types";

export const loginUser = (userData, history) => dispatch => {
  dispatch({ type: LOADING_UI });
  store.dispatch({ type: SET_UNAUTHENTICATED });

  axios
    .post("/login.php", userData)
    .then(res => {
      let size =
        res.data.error == null ? 0 : Object.keys(res.data.error).length;

      let role = res.data.success == null ? 0 : res.data.success.role;

      if (size == 0) {
        localStorage.setItem("userId", res.data.success.id);
        dispatch({
          type: SET_USER,
          payload: res.data.success
        });

        setAuthorizationHeader(res.data.token);
        dispatch(getCustomer({ id: res.data.success.id }));
        dispatch({ type: CLEAR_ERRORS });
        store.dispatch({ type: SET_AUTHENTICATED });
        localStorage.setItem("isLogged", res.data.isLogged ? true : false);
        localStorage.setItem("isAdmin", role == "ADMIN" ? true : false);
        role == "ADMIN" ? history.push("/admin") : history.push("/");
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: res.data.error
        });
      }
    })
    .catch(err => {
      console.log(err);
      // dispatch({
      //   type: SET_ERRORS,
      //   payload: err.response.data
      // });
    });
};

// export const signupUser = (newUserData, history) => dispatch => {
//   dispatch({ type: LOADING_UI });
//   axios.post("/signup.php", newUserData).then(res => {
//     let size = res.data.error == null ? 0 : Object.keys(res.data.error).length;

//     if (size == 0) {
//       localStorage.setItem("userId", res.data.success.id);

//       dispatch({
//         type: SET_USER,
//         payload: res.data.success
//       });
//       setAuthorizationHeader(res.data.token);
//       // dispatch(getUserData());
//       dispatch({ type: CLEAR_ERRORS });
//       store.dispatch({ type: SET_AUTHENTICATED });
//       history.push("/editar");
//     } else {
//       dispatch({
//         type: SET_ERRORS,
//         payload: res.data.error
//       });
//     }
//   });
// };

export const signupUser = (
  newUserData,
  userAdditionalData,
  history
) => dispatch => {
  dispatch({ type: LOADING_UI });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .post("/signup.php", newUserData)
    .then(res => {
      let size =
        res.data.error == null ? 0 : Object.keys(res.data.error).length;

      if (size == 0) {
        localStorage.setItem("userId", res.data.success.id);

        dispatch({
          type: SET_USER,
          payload: res.data.success
        });

        setAuthorizationHeader(res.data.token);

        dispatch({ type: CLEAR_ERRORS });

        store.dispatch({ type: SET_AUTHENTICATED });
      } else {
        dispatch({
          type: SET_ERRORS,
          payload: res.data.error
        });
        throw new Error("erro no cadastro");
      }
    })
    .then(() => {
      userAdditionalData.id = localStorage.getItem("userId");
      let userRegister = { ...userAdditionalData, ...newUserData };
      axios.post("/userUpdate.php", userRegister).then(res => {
        if (res.data) {
          history.push("/");
        }
      });
    })
    .catch(err => console.log(err));
};

// Get one customers
export const getCustomer = itemData => dispatch => {
  // dispatch({ type: LOADING_DATA });
  axios
    .post("/admin_customers/read_customer.php", itemData)
    .then(res => {
      dispatch({
        type: SET_USER,
        payload: res.data
      });
      return true;
    })
    .catch(err => {
      dispatch({
        type: SET_CUSTOMER,
        payload: []
      });
    });
};
export const logoutUser = () => dispatch => {
  localStorage.removeItem("FBIdToken");
  delete axios.defaults.headers.common["Authorization"];
  dispatch({ type: SET_UNAUTHENTICATED });
};

export const getUserData = () => dispatch => {
  dispatch({ type: LOADING_USER });
  axios
    .get("/user.php")
    .then(res => {
      dispatch({
        type: SET_USER,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const uploadImage = formData => dispatch => {
  dispatch({ type: LOADING_USER });
  axios
    .post("/user/image", formData)
    .then(() => {
      dispatch(getUserData());
    })
    .catch(err => console.log(err));
};

export const editUserDetails = userDetails => dispatch => {
  dispatch({ type: LOADING_USER });
  axios
    .post("/user", userDetails)
    .then(() => {
      dispatch(getUserData());
    })
    .catch(err => console.log(err));
};

export const markNotificationsRead = notificationIds => dispatch => {
  axios
    .post("/notifications", notificationIds)
    .then(res => {
      dispatch({
        type: MARK_NOTIFICATIONS_READ
      });
    })
    .catch(err => console.log(err));
};

const setAuthorizationHeader = token => {
  const FBIdToken = `Bearer ${token}`;
  localStorage.setItem("FBIdToken", FBIdToken);
  axios.defaults.headers.common["Authorization"] = FBIdToken;
};
