import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Route, Redirect } from "react-router-dom";

import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import AppIcon from "../assets/images/logo.png";
import { Link } from "react-router-dom";

// MUI Stuff
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
// Redux stuff
import { connect } from "react-redux";
import { getCustomer } from "../redux/actions/userActions";
//
import Title from "../components/text-title";
import { maskInput } from "../util/maskedInput";
//
import axios from "axios";
//
import "./edituser.scss";
import Navbar from "../components/navbar";

const styles = theme => ({
  ...theme.spreadIt
});

class EditUser extends Component {
  constructor(props) {
    super();
    this.state = { user: props.user, errors: {} };
  }

  componentDidMount() {
    if (localStorage.userId == undefined) this.props.history.push("/entrar");
    this.props.getCustomer({ id: localStorage.userId });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ user: nextProps.user });

    if (nextProps.UI.errors) {
      this.setState({ user: this.props.user, errors: nextProps.UI.errors });
    }
  }
  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      loading: true
    });
    axios
      .post("/userUpdate.php", this.state.user)
      .then(res => {
        if (res.data == true) {
          return true;
        } else {
          this.setState({ errors: { general: "Falha em salvar os dados" } });
        }
      })
      .then(result => {
        result ? this.setState({ success: true }) : "";
      })

      .catch(err => console.log(err));
  };

  handleChange = (event, mask) => {
    let masked = maskInput(event.target.value, mask);

    this.setState({
      user: {
        ...this.state.user,
        [event.target.id]: masked
      }
    });

    if (mask == "cep") {
      axios.get("/cep_api.php?cep=" + event.target.value).then(res => {
        this.setState({
          user: {
            ...this.state.user,
            logradouro: res.data["logradouro"],
            bairro: res.data["bairro"],
            cidade: res.data["municipio"],
            estado: res.data["uf"]
          },
          errors: {
            general: res.data["input-codigo-error"]
          }
        });
      });
    }
  };

  render() {
    const {
      classes,
      UI: { loading }
    } = this.props;
    const { errors } = this.state;

    const formFields1 = [
      {
        required: true,
        id: "username",
        label: "Nome Completo",
        type: "text"
      },
      {
        required: true,
        id: "email",
        label: "E-Mail",
        type: "text"
      },
      {
        required: true,
        id: "cpf",
        label: "CPF",
        type: "text"
      },
      {
        required: true,
        id: "telefone",
        label: "Telefone",
        type: "text"
      }
    ];

    const formFields2 = [
      {
        required: true,
        id: "cep",
        label: "CEP",
        type: "text"
      },
      {
        required: true,
        id: "logradouro",
        label: "Rua",
        type: "text"
      },
      {
        required: true,
        id: "numero",
        label: "Número",
        type: "text"
      },
      {
        required: false,
        id: "complemento",
        label: "Complemento",
        type: "text"
      }
    ];

    const formFields3 = [
      {
        required: true,
        id: "bairro",
        label: "Bairro",
        type: "text"
      },
      {
        required: true,
        id: "cidade",
        label: "Cidade",
        type: "text"
      },
      {
        required: true,
        id: "estado",
        label: "Estado",
        type: "text"
      }
    ];
    if (this.state.success) return <Redirect to="/" />;

    return (
      <div className="edituser" align="center">
        <Navbar />
        <Grid
          container
          className={classes.form}
          align="center"
          justify="center"
        >
          <Grid item align="center" sm={12} md={4}>
            <br />
            <img
              id="logo"
              src={AppIcon}
              alt="Co2 Brasil"
              className={classes.image}
            />
            <Typography variant="h3" className={classes.pageTitle}>
              Minha Conta
            </Typography>
          </Grid>
        </Grid>
        <form
          style={{ display: "flex", padding: "0px 20px" }}
          onSubmit={this.handleSubmit}
        >
          <Grid container justify="center" spacing={2}>
            <Grid item sm={12} md={3} className="form" align="center">
              {formFields1.map(field => (
                <TextField
                  key={field.id}
                  fullWidth
                  style={{ margin: "30px 0px" }}
                  className={classes.textField}
                  required={field.required}
                  id={field.id}
                  label={field.label}
                  type={field.type}
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="standard"
                  value={this.state.user[field.id]}
                  onChange={e => this.handleChange(e, field.id)}
                />
              ))}
            </Grid>
            <Grid item sm={12} md={3} className="form" align="center">
              {formFields2.map(field => (
                <TextField
                  key={field.id}
                  fullWidth
                  style={{ margin: "30px 0px" }}
                  className={classes.textField}
                  required={field.required}
                  id={field.id}
                  label={field.label}
                  type={field.type}
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="standard"
                  value={this.state.user[field.id]}
                  onChange={e => this.handleChange(e, field.id)}
                />
              ))}
            </Grid>
            <Grid item sm={12} md={3} className="form">
              {formFields3.map(field => (
                <TextField
                  key={field.id}
                  fullWidth
                  style={{ margin: "30px 0px" }}
                  className={classes.textField}
                  required={field.required}
                  id={field.id}
                  label={field.label}
                  type={field.type}
                  InputLabelProps={{
                    shrink: true
                  }}
                  variant="standard"
                  value={this.state.user[field.id]}
                  onChange={e => this.handleChange(e, field.id)}
                />
              ))}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={loading}
                style={{ marginTop: 20 }}
              >
                Salvar
                {loading && (
                  <CircularProgress size={30} className={classes.progress} />
                )}
              </Button>
              <br />
              <br />
              {this.state.errors.general && (
                <Typography
                  variant="body2"
                  className={classes.customError}
                  style={{ color: "red" }}
                >
                  {this.state.errors.general}
                </Typography>
              )}
            </Grid>
            <Grid
              style={{ height: 100 }}
              item
              sm={12}
              md={4}
              align="center"
            ></Grid>
          </Grid>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  getCustomer
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(EditUser));
