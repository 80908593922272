import React, { Component } from "react";
import "./ticket.css";
import Modal from "../modal-confirm";
import withStyles from "@material-ui/core/styles/withStyles";

// Redux stuff
import { connect } from "react-redux";
import { getCustomer } from "../../redux/actions/userActions";
// API
import axios from "axios";

const styles = (theme) => ({
  ...theme.spreadIt,
});

class index extends Component {
  constructor(props) {
    super(props);
    this.handleOnMouseEnter = this.handleOnMouseEnter.bind(this);
    this.handleOnMouseLeave = this.handleOnMouseLeave.bind(this);
    this.state = {
      hover: false,
      winner: { number: props.sweepstake.winner },
      user: {},
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.loading) {
      this.interval = setTimeout(
        () => this.startAnimation(),
        this.props.number * (5000 / this.props.sweepstake.numberOfTickets)
      );
    } else {
      this.stopAnimation();
    }
    if (nextProps.winner != undefined) {
      this.setState({ winner: nextProps.winner });
    }

    if (this.state.user.credit != nextProps.user.credit) {
      this.setState({ user: nextProps.user });
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  handleOnMouseEnter(userName, number) {
    if (userName != "") {
      let response = confirm(
        "Excluir! Deseja excluir o número deste cliente? O número " +
          number +
          " foi escolhido por: " +
          userName
      );
      if (response === true) {
        const { sweepstakeId, number } = this.props;
        axios
          .post("/delete_number.php", { sweepstakeId, ticketNumber: number })
          .then(() => alert(`O número ${this.props.number} foi restaurado.`))
          .catch((err) => alert(err));
      } else {
        alert(`Cancelado.`);
      }
    }
    this.setState({ hover: true });
  }
  handleOnMouseLeave() {
    this.setState({ hover: false });
  }

  startAnimation = () => {
    document
      .getElementById("ticket-" + this.props.number)
      .classList.add("animated", "bounce", "infinite", "fast");
    document
      .getElementById("ticket-" + this.props.number)
      .classList.remove("jackInTheBox", "ticket-winner", "box-winner");
  };
  stopAnimation = () => {
    clearTimeout(this.interval);
    document
      .getElementById("ticket-" + this.props.number)
      .classList.remove("bounce");
  };

  handleTicketClick = () => {
    if (this.props.userName !== "") {
      let response = confirm(
        `O número ${this.props.number} pertence ao ${this.props.userName}.` +
          (this.checkWinner() ? " E este número foi sorteado." : "")
      );
    } else {
      this.handleOnMouseLeave();
    }
  };

  render() {
    return (
      <>
        <div
          id={`ticket-${this.props.number}`}
          className={
            "hover ticket " +
            (this.state.hover && this.props.userName == "" ? "flip " : " ") +
            (this.props.sweepstake.winner == this.props.number
              ? "animated jackInTheBox infinite ticket-winner "
              : "")
          }
          onClick={this.handleTicketClick}
        >
          <div className="front">
            <div
              className={
                "box " +
                (this.props.userName != ""
                  ? this.props.sweepstake.winner == this.props.number
                    ? "box-winner"
                    : "box-unavailable"
                  : "box1")
              }
              onClick={() =>
                this.handleOnMouseEnter(this.props.userName, this.props.number)
              }
            >
              <p className={this.props.userName != "" ? "text-fade" : ""}>
                {this.props.number}
              </p>
              <p className="ticket-user">{this.props.userName}</p>
            </div>
          </div>
          <div className="back">
            <div className="box box2" onClick={this.handleOnMouseLeave}>
              <p>{this.props.number}</p>
            </div>
          </div>
          {this.props.sweepstake.winner > 1 ? (
            ""
          ) : this.props.userName != "" ||
            this.state.user.role == "ADMIN" ||
            this.state.user.role == undefined ? null : (
            <Modal
              selected={this.state.selected}
              number={this.props.number}
              handleTicketSave={this.props.handleTicketSave}
              sweepstakeId={this.props.sweepstakeId}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  getCustomer,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(index));
