import React, { Component } from "react";
import Sweepstake from "./admin-sweepstake-view";
import { connect } from "react-redux";
// MUI
import withStyles from "@material-ui/core/styles/withStyles";
// Redux
import { getSweepstakes } from "../../redux/actions/dataActions";
//
import axios from "axios";
//
import "./admin-sweepstake.scss";

const styles = theme => ({});

class LoginFormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ticketArray: [],
      superChargedSweepstakes: []
    };
  }

  componentDidMount() {
    this.props.getSweepstakes();
    // this.getSelectedTickets();
    this.interval = setInterval(() => this.getSelectedTickets(), 10000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentWillReceiveProps(nextProps) {
    this.getSelectedTickets();
  }

  getSelectedTickets = () => {
    axios.get("/sweepstake_statistics.php").then(res => {
      this.setState({ ticketArray: res.data });
      this.setState({ superChargedSweepstakes: this.pushItems() });
    });
  };

  getTotalSelected = sweepstakeId => {
    return this.state.ticketArray.filter(
      item => item.sweepstakeId == sweepstakeId
    ).length;
  };

  pushItems = () => {
    const newRows = this.props.sweepstakes.map(row => {
      const newRow = {
        ...row,
        selected: this.getTotalSelected(row.id),
        available: row.numberOfTickets - this.getTotalSelected(row.id)
      };
      return newRow;
    });
    return newRows;
  };

  render() {
    const { getSweepstakes } = this.props;
    const { superChargedSweepstakes, ticketArray, handleSubmit } = this.state;

    return (
      <div className="admin-sweepstake admin-content">
        <Sweepstake
          sweepstakes={superChargedSweepstakes}
          ticketArray={ticketArray}
          handleSubmit={handleSubmit}
          getSweepstakes={getSweepstakes}
        />
      </div>
    );
  }
}

// REDUX
const mapStateToProps = state => ({
  sweepstakes: state.data.sweepstakes,
  UI: state.UI
});

const mapActionsToProps = {
  getSweepstakes
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(LoginFormContainer));
