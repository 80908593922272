import React from "react";
// Material UI
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
// Componets
import "./modal-confirm.scss";

// Redux
import { connect } from "react-redux";
import { removeCredit } from "../../redux/actions/userActions";
import { getCustomer } from "../../redux/actions/userActions";

import axios from "axios";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    // width: 400,
    maxWidth: "400px",
    minWidth: "200px",

    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    borderRadius: "16px",
    outline: "none !important",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  }
}));

// export default function SimpleModal(props) {
function SimpleModal(props) {
  const { user } = props;
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // document.body.classList.add("overflow-visible");
  };

  const handleSaveNumber = () => {
    let sweepstakeId = props.sweepstakeId;
    let userId = props.user.id;
    let ticket_number = props.number;

    let itemData = { sweepstakeId, userId, ticket_number };
    props.handleTicketSave(itemData);
    setOpen(false);
  };

  const confirm = () => {
    if (props.user != undefined && props.user.credit > 0) {
      return (
        <>
          <p className="modal-title ">
            Deseja escolher o número {props.number}?
          </p>
          <div className={classes.root}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSaveNumber()}
            >
              Sim
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleClose()}
            >
              Não
            </Button>
            <SimpleModal />
          </div>
        </>
      );
    }
  };

  return (
    <div className="ticket-modal">
      <button className="button-transparent" type="button" onClick={handleOpen}>
        Open Modal
      </button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div
          style={modalStyle}
          className={"center modal-dialog " + classes.paper}
        >
          <p className="modal-title ">
            {user != undefined
              ? user.username + ", você possui " + user.credit + " créditos."
              : "error"}
          </p>
          {confirm()}
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

// const mapDispatchToProps = dispatch => {
//   return {
//     removeCredit: () => {
//       dispatch(removeCredit());
//     }
//   };
// };
const mapActionsToProps = {
  // getSweepstakes,
  getCustomer
};
export default connect(mapStateToProps, mapActionsToProps)(SimpleModal);
