import React from "react";
import "./dashboard.scss";
const DashboardView = ({ text }) => {
  return (
    <div className="admin-content dashboard">
      <h2>{text}</h2>
    </div>
  );
};

export default DashboardView;
