import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import brLocale from "date-fns/locale/pt-br";

import axios from "axios";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DatePicker
} from "@material-ui/pickers";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

export default function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [form, setForm] = React.useState({
    endDate: new Date(),
    numberOfTickets: 3
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    handleCreateSweepstake();
    handleClose();
  };

  const startDate = () => {
    var today = new Date();
    var date =
      today.getDate() +
      "/" +
      (today.getMonth() + 1) +
      "/" +
      today.getFullYear();
    return date;
  };

  const handleDateChange = date => {
    let date_current =
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear();
    setForm({
      ...form,
      endDate: date_current,
      startDate: startDate(),
      available: form.numbers
    });
  };

  const handleCreateSweepstake = () => {
    let date_current =
      ("0" + form.endDate.getDate()).slice(-2) +
      "/" +
      ("0" + (form.endDate.getMonth() + 1)).slice(-2) +
      "/" +
      form.endDate.getFullYear();

    let itemData = {
      title: form.title,
      numberOfTickets: form.numberOfTickets,
      endDate: date_current
    };

    axios
      .post("/admin_sweepstakes/create.php", itemData)
      .then(res => {
        props.getSweepstakes();
      })
      .catch(err => {
        console.log("credit catch: ", err);
      });
  };

  const handleChange = e => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
      startDate: startDate(),
      available: e.target.value
    });
  };

  const formFields = [
    {
      required: true,
      id: "title",
      label: "Nome da Campanha",
      type: "text"
    },
    {
      required: true,
      id: "numberOfTickets",
      label: "Quantidade Números",
      type: "number"
    }
  ];

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpen}>
        Criar
      </Button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <h3 id="simple-modal-title">Criar Sorteio</h3>
          <form
            className={classes.root}
            autoComplete="off"
            onSubmit={e => handleSubmit(e)}
            id="abc"
          >
            {formFields.map(field => (
              <TextField
                key={field.id}
                style={{ display: "block", margin: "30px 0px" }}
                required={field.required}
                id={field.id}
                label={field.label}
                type={field.type}
                InputLabelProps={{
                  shrink: true
                }}
                variant="standard"
                onChange={e => handleChange(e)}
              />
            ))}

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
              <DatePicker
                initialFocusedDate="22/22/2022"
                required
                style={{ width: 200, display: "block" }}
                margin="normal"
                id="endDate"
                label="Data Sorteio"
                format="dd/MM/yyyy"
                value={form.endDate}
                onChange={e =>
                  handleChange({ target: { id: "endDate", value: e } })
                }
              />
            </MuiPickersUtilsProvider>

            <Button
              style={{ display: "block" }}
              variant="contained"
              color="primary"
              type="submit"
              form="abc"
            >
              Salvar
            </Button>
          </form>
        </div>
      </Modal>
    </div>
  );
}
