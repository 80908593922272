import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// MUI
import withStyles from "@material-ui/core/styles/withStyles";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Menu from "@material-ui/icons/Menu";
import MailIcon from "@material-ui/icons/Mail";
import Receipt from "@material-ui/icons/Receipt";
import AccountBox from "@material-ui/icons/AccountBox";
import IconButton from "@material-ui/core/IconButton";

// Redux
import { getSweepstakes } from "../../redux/actions/dataActions";
import { getCustomer } from "../../redux/actions/userActions";
import { connect } from "react-redux";

// Style
import "./main-menu.scss";

const styles = theme => ({});

const useStyles = makeStyles({
  list: {
    width: 250
  },
  fullList: {
    width: "auto"
  }
});

function TemporaryDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const menuItens = [];

  if (props.user.authenticated)
    menuItens.push({ text: "Sorteios", icon: <Receipt />, link: "/" });
  if (props.user.authenticated)
    menuItens.push({
      text: "Minha Conta",
      icon: <AccountBox />,
      link: "/editar"
    });

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        {/* {["Inbox", "Starred", "Send email", "Drafts"].map((text, index) => ( */}
        {menuItens.map((item, index) => (
          <Link className="link" key={item.text} to={item.link}>
            <ListItem button>
              <ListItemIcon>
                {/* {index % 2 === 0 ? <InboxIcon /> : <MailIcon />} */}
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );

  return props.user.authenticated ? (
    <IconButton
      edge="start"
      className={classes.menuButton}
      color="inherit"
      aria-label="menu"
    >
      <div className="main-menu">
        <Button color="inherit" onClick={toggleDrawer("left", true)}>
          <Menu /> Menu
        </Button>
        <Drawer open={state.left} onClose={toggleDrawer("left", false)}>
          {sideList("left")}
        </Drawer>
      </div>
    </IconButton>
  ) : null;
}

// REDUX
const mapStateToProps = state => ({
  sweepstakes: state.data.sweepstakes,
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  getSweepstakes,
  getCustomer
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(TemporaryDrawer));
