import React, { useState } from "react";
import { Route, Redirect, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";

// Components
import MainMenu from "../main-menu";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1
  }
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();

  const [redirect, setredirect] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("userId");
    setredirect(true);
  };

  if (redirect) {
    localStorage.removeItem('userId')
    return <Redirect push to="/entrar" />;
  }

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {/* <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          > */}
          <MainMenu />
          {/* </IconButton> */}
          <Typography variant="h6" className={classes.title}>
            Co2 Brasil
          </Typography>
            <Button onClick={() => handleLogout()} color="inherit">
              Sair
            </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
