import React, { Component } from "react";

// Components
import NavbarView from "./navbar-view";
import "./admin-navbar";

class NavbarContainer extends Component {
  render() {
    return <NavbarView />;
  }
}
export default NavbarContainer;
