import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
// MUI
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
//
import axios from "axios";

const useStyles = makeStyles({
  card: {
    maxWidth: 345,
    minWidth: 250,
    margin: "20px",
  },
  media: {
    height: 194,
    backgroundSize: "100%",
  },
});

export default function MediaCard(props) {
  const classes = useStyles();

  const newTo = {
    pathname: "/cartela/" + props.sweepstake.id,
    state: {
      sweepstake: props.sweepstake,
      user: props.user,
    },
  };

  const [image, setImage] = useState("no-image.png");

  useEffect(() => {
    fetchImage();
  }, []);

  const fetchImage = () => {
    axios.post("/imageGet.php", { id: props.sweepstake.id }).then((res) => {
      setImage(res.data);
    });
  };
  return (
    <Card
      className={classes.card}
      elevation={2}
      style={props.fade ? { background: "#0000006b" } : {}}
    >
      <Link className="link" to={newTo}>
        <CardActionArea style={{ minWidth: 120 }}>
          <CardMedia
            className={classes.media}
            // image={`https://${window.location.hostname}/Development/login-system/api/images/${image}`}
            image={`https://${window.location.hostname}/interface/api/images/${image}`}
            title="Sorteio"
          />
          <CardContent>
            {props.sweepstake.title != null ? (
              <Typography gutterBottom variant="h5">
                {props.sweepstake.title.replace(/&quot;/g, '"')}
              </Typography>
            ) : null}
            <Typography gutterBottom variant="h6">
              {props.sweepstake.winner > 0
                ? "Número Sorteado: " + props.sweepstake.winner
                : ""}
            </Typography>

            <Typography gutterBottom variant="h6">
              Data: {props.sweepstake.endDate}
            </Typography>

            <Typography gutterBottom variant="h6">
              Números: {props.sweepstake.numberOfTickets}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}
