import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import PersonIcon from "@material-ui/icons/Person";
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import { blue } from "@material-ui/core/colors";

const emails = ["username@gmail.com", "user02@gmail.com"];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  }
});

function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  const hideString = (str, quantity) => {
    if (str != undefined) {
      return str.substring(0, quantity) + Array(str.length - 2).join("*");
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Ganhador</DialogTitle>

      <List>
        <ListItem key={props.ss.username}>
          Nome:&nbsp;<b>{props.ss.username}</b>
        </ListItem>
        <ListItem key={props.ss.email}>
          Email:&nbsp;<b>{hideString(props.ss.email, 5)}</b>
        </ListItem>
        <ListItem key={props.ss.telefone}>
          Telefone:&nbsp;<b>{hideString(props.ss.telefone, 9)}</b>
        </ListItem>
        <ListItem key={props.ss.cidade}>
          Cidade:&nbsp;<b> {props.ss.cidade}</b>
        </ListItem>
        <ListItem key={props.ss.estado}>
          Estado:&nbsp;<b>{props.ss.estado}</b>
        </ListItem>
      </List>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired
};

export default function SimpleDialogDemo(props) {
  const [open, setOpen] = React.useState(props.openModal);
  const [selectedValue, setSelectedValue] = React.useState(emails[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
    setSelectedValue(value);
  };

  useEffect(() => {
    setOpen(props.openModal);
  }, [props.openModal]);

  return (
    <div>
      {/* <Typography variant="subtitle1">Selected: {selectedValue}</Typography>
      <br />
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open simple dialog
      </Button> */}
      <SimpleDialog
        // selectedValue={selectedValue}
        ss={props.ss}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
}
