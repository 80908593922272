import React from "react";

// Redux
import { getCustomers } from "../../../redux/actions/dataActions";
import { connect } from "react-redux";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import withStyles from "@material-ui/core/styles/withStyles";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
//
import axios from "axios";

const styles = theme => ({
  ...theme.spreadIt
});

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    display: "block"
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

function SimpleModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [form, setForm] = React.useState({
    name: props.user != undefined ? props.user.username : "",
    email: props.user != undefined ? props.user.email : "",
    credit: props.user != undefined ? props.user.credit : "",
    roles_mask: props.user != undefined ? props.user.roles_mask : "",

    id: props.user != undefined ? props.user.id : ""
  });

  const [age, setAge] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = e => {
    let value = e.target.value < 0 ? 0 : e.target.value;
    setForm({
      ...form,
      [e.target.id]: value
    });
  };
  const handleSubmit = e => {
    handleCredit();
    e.preventDefault();
    handleClose();
  };

  const handleCredit = () => {
    let userData = {
      id: form.id,
      credit: form.credit,
      username: form.name,
      roles_mask: form.roles_mask,
      email: form.email
    };

    axios
      .post("/admin_customers/update.php", userData)
      .then(res => {
        props.getCustomers();
      })
      .catch(err => {
        console.log("credit catch: ", err);
      });
  };

  return (
    <div>
      <div onClick={handleOpen}>{props.children}</div>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <h3 id="simple-modal-title">Alterar Créditos</h3>
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <FormControl className={classes.formControl}>
              <TextField
                id="credit"
                label="Crédtitos"
                type="number"
                InputLabelProps={{
                  shrink: true
                }}
                variant="standard"
                onChange={e => handleChange(e)}
                value={form.credit}
              />
            </FormControl>

            <Button
              style={{ display: "block", marginTop: 20 }}
              variant="contained"
              color="primary"
              // onClick={handleSubmit}
              type="submit"
            >
              Salvar
            </Button>
          </form>
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = state => ({
  customers: state.data.customers,
  UI: state.UI
});

const mapActionsToProps = {
  getCustomers
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(SimpleModal));
