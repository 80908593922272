import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// Icons
import InboxIcon from "@material-ui/icons/MoveToInbox";
import Menu from "@material-ui/icons/Menu";
import Home from "@material-ui/icons/Home";
import MailIcon from "@material-ui/icons/Mail";
import PeopleAlt from "@material-ui/icons/PeopleAlt";
import Receipt from "@material-ui/icons/Receipt";
import LiveTv from "@material-ui/icons/LiveTv";
//
import Sidebar from "../sidebar";

// Style
import "./main-menu.scss";

const useStyles = makeStyles({
  list: {
    // width: 250
  },
  fullList: {
    width: "auto"
  }
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (side, open) => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const menuItens = [
    { text: "Home", icon: <Home />, link: "/admin" },
    { text: "Sorteios", icon: <Receipt />, link: "/admin/sorteios" },
    { text: "Clientes", icon: <PeopleAlt />, link: "/admin/clientes" },
    { text: "Live", icon: <LiveTv />, link: "/admin/live_sorteios" }
  ];

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      {/* <List>
        {menuItens.map((item, index) => (
          <Link className="link" key={item.text} to={item.link}>
            <ListItem button>
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} />
            </ListItem>
          </Link>
        ))}
      </List> */}
      <Sidebar />
    </div>
  );

  return (
    <div className="main-menu">
      <Button color="inherit" onClick={toggleDrawer("left", true)}>
        <Menu /> Menu
      </Button>
      <Drawer open={state.left} onClose={toggleDrawer("left", false)}>
        {sideList("left")}
      </Drawer>
    </div>
  );
}
