import React, { useState, useEffect } from "react";
import { Route, Redirect, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
//
// import MobileMenu from "../main-menu-mobile";
// import MobileMenu from "../admin-navbar";
// Components
import MainMenu from "../admin-main-menu";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    marginLeft: 60
  }
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  const [redirect, setredirect] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("userId");
    setredirect(true);
  };

  if (redirect) {
    localStorage.removeItem("userId");
    return <Redirect push to="/entrar" />;
  }

  // handle mobile  - start

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  const isMobile = width <= 500;

  // handle mobile - end

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar>
          {isMobile ? <MainMenu /> : ""}
          <Typography variant="h6" className={classes.title}>
            Co2 Brasil
          </Typography>
          <Button onClick={() => handleLogout()} color="inherit">
            Sair
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
